<template>
    <div class="books">
        <Header class="hide" />
    <Headers class="dispy" />
        <BannerImage rulename="query_banner"  />
        <div class="w">
            <Breadcrumb :crumblist="crumbdata" class="hide" />
            <div class="strip">
                <div class="car">
                    书籍光碟
                </div>
            </div>
			<FourcolLists :urllist="list" detailurl="/books/detail" />
        </div>
        <Footer />
    </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import BannerImage from "@/components/main/BannerImage";
import FourcolLists from "@/components/main/FourcolLists";

export default {
    components: {
        Header,
        Footer,
        Headers,
		Breadcrumb,
		BannerImage,
		FourcolLists
	},
	data() {
		return {
			list: "/bc/bookCd/list",
			crumbdata: [
                { name: "信息查询", url: "" },
                { name: "书籍光碟", url: "/books" },
            ],
		}
	},
};
</script>

<style lang="scss" scoped>
</style>
